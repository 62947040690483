const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);

export async function senMsgToOpenAI(message) {
    console.log(openai);
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "OpenAI/NodeJS/3.3.0",
        Authorization: `Bearer ${openai.configuration.apiKey}`,
      },
      body: JSON.stringify({
        prompt: message,
        temperature: 0.7,
        max_tokens: 100,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      }),
    };
  
    try {
      const response = await fetch(
        "https://api.openai.com/v1/engines/text-davinci-003/completions",
        requestOptions
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data.choices && data.choices.length > 0) {
        console.log("Response:", data.choices[0].text);
        return data.choices[0].text;
      } else {
        console.error("Unexpected response format:", data);
        return null; // or handle the error accordingly
      }
    } catch (error) {
      console.error("Error:", error);
      return null; // or handle the error accordingly
    }
  }
  
